import React from 'react'
import Banner from './banner/Banner'
import c from './home.module.scss'

const Home = () => {
  return (
    <div className={c.Home}>
      <Banner />
    </div>
  )
}

export default Home
