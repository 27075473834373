import React from 'react'
import { Link } from '../../utils/Router'
import Logo from '../utilities/icons/Logo'
import c from './topnav.module.scss'
import ProfileMenu from './profile-menu/ProfileMenu'

const Topnav = ({ noProfile = false }) => {
  return (
    <div className={c.topnav} id='top-nav'>
      <div className={c.left}>
        <Logo.MonashStacked />
        <div className={c.divider} />
        <Link to='/' exact>
          <h2 className={c.title}>User Guide - Beta</h2>
        </Link>
      </div>
      <div className={c.right}>
        <span> {!noProfile && <ProfileMenu />}</span>
      </div>
    </div>
  )
}

export default Topnav
