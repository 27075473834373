import React from 'react'
import c from './banner.module.scss'
import Lottie from 'react-lottie'
import appLogo from '../../../../assets/lotties/course-mapper.json'
import { Link } from '../../../../utils/Router'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: appLogo,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Banner = () => {
  return (
    <div className={c.Banner}>
      <h1>Course Mapper User Guide</h1>
      <p>A single, digital and integrated course mapping platform</p>
      <div className={c.links}>
        <Link to={`course-mapper/guides/introduction/what-is-course-mapper`}>
          Guides & Tutorials
        </Link>
        <Link to={`faqs`}>FAQs</Link>
      </div>
      <div className={c.appLogo}>
        <Lottie options={defaultOptions} height={300} width={400} />
      </div>
    </div>
  )
}

export default Banner
