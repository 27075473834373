import React, { useContext, useEffect, useRef, useState } from 'react'
import Icon from '../../../utilities/icons/Icon'
import c from './vote.module.scss'
import { fs } from '../../../../utils/firebase'
import { UserData } from '../../../../utils/wrappers/user-data-wrapper/UserDataWrapper'
import 'firebase/firestore'
import clsx from 'clsx'

const Vote = ({ activeArticle }) => {
  const { user } = useContext(UserData)
  const [userVote, setUserVote] = useState(undefined)
  const [upVoteCount, setUpVoteCount] = useState(0)
  const [downVoteCount, setDownVoteCount] = useState(0)

  //unsubcribe to vote status and counts when active article changes
  const unsubVoteStatusRef = useRef(null)
  const unsubVoteCountRef = useRef(null)

  const upsertVote = async (vote) => {
    setUserVote(vote)
    await fs
      .collection('users')
      .doc(user.monashObjectID)
      .collection('votes')
      .doc(activeArticle.id)
      .set({
        vote: vote,
      })
  }

  const removeVote = async (vote) => {
    setUserVote(undefined)
    await fs
      .collection('users')
      .doc(user.monashObjectID)
      .collection('votes')
      .doc(activeArticle.id)
      .delete()
  }

  const castVote = (vote) => {
    switch (userVote) {
      case 'up':
        if (vote === 'up') {
          removeVote(vote)
        } else {
          upsertVote(vote)
        }
        break
      case 'down':
        if (vote === 'down') {
          removeVote(vote)
        } else {
          upsertVote(vote)
        }
        break
      default:
        upsertVote(vote)
    }
  }

  //TODO: Consider how to make the update quicker / delay vote count display

  //Subscribe to user vote status
  useEffect(() => {
    if (!activeArticle) {
      return null
    }
    unsubVoteStatusRef.current?.()
    unsubVoteStatusRef.current = fs
      .collection('users')
      .doc(user.monashObjectID)
      .collection('votes')
      .doc(activeArticle.id)
      .onSnapshot((doc) => {
        setUserVote(doc?.data()?.vote)
      })
    return () => unsubVoteStatusRef.current?.()
  }, [user, activeArticle])

  //Subscribe to article vote counts
  useEffect(() => {
    if (!activeArticle) {
      return null
    }
    unsubVoteCountRef.current?.()
    unsubVoteCountRef.current = fs
      .collection('articles')
      .doc(activeArticle.id)
      .onSnapshot((doc) => {
        setUpVoteCount(doc.data().votes.upvotes)
        setDownVoteCount(doc.data().votes.downvotes)
      })
    return () => unsubVoteCountRef.current?.()
  }, [user, activeArticle])

  if (!activeArticle) {
    return null
  }

  return (
    <div className={c.vote}>
      <div className={c.votebuttons}>
        <button
          className={clsx(userVote === 'up' && c.voted)}
          onClick={() => castVote('up')}>
          Useful {upVoteCount} <Icon.ThumbUp />
        </button>
        <button
          className={clsx(userVote === 'down' && c.voted)}
          onClick={() => castVote('down')}>
          Not really {downVoteCount} <Icon.ThumbDown />
        </button>
      </div>
      {userVote === 'up' ? (
        <div className={c.feedback}>Thanks for your feedback!</div>
      ) : null}
      {userVote === 'down' ? (
        <div className={c.feedback}>
          Sorry about that. Tell us why by submitting your feedback or issue{' '}
          <a
            href='https://docs.google.com/spreadsheets/d/1tvlvLU9B8G1YzXcim4LCXBDftZgMF8_H2L-g7vMoWs8/edit#gid=1637832102'
            target='_blank'
            rel='noreferrer'>
            here <Icon.External />
          </a>
        </div>
      ) : null}
    </div>
  )
}

export default Vote
